body {
    font-size: 14px;
    color: #000000;
}

.app-wrapper {
    position: relative;
}

.app-wrapper::before {
    content: "";
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0065a4;
    background-image: url('../images/bg_image-min.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0 0;
}

.app-wrapper .form-control,
.app-wrapper .form-select,
.app-wrapper .btn {
    border-radius: 0;
    border-color: #000000;
}

.app-wrapper .form-control:hover,
.app-wrapper .form-control:focus,
.app-wrapper .form-control:active,
.app-wrapper .form-select:hover,
.app-wrapper .form-select:focus,
.app-wrapper .form-select:active,
.app-wrapper .btn:hover,
.app-wrapper .btn:focus,
.app-wrapper .btn:active {
    box-shadow: none;
}

.app-content {
    position: relative;
    color: #000000;
}

.box-wrapper {
    width: 90%;
    margin: 0 auto;
    border-width: 2px;
    border-style: solid;
    border-color: #bbbabb;
    border-radius: 0;
    padding: 2rem 4rem;
    background-color: rgba(255, 255, 255, 1);
}

.fs-11 {
    font-size: 3rem !important;
}

.login-box {
    max-width: 450px;
}

.login-text {
    color: #efad1e;
    font-size: 30px;
    font-weight: 200;
}

.app-body {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    overflow-x: hidden;
    margin-top: 67.95px;
    /* background-color: #ffffff; */
}

.app-wrapper a {
    text-decoration: none;
}

.app-body>main {
    flex: 1 1;
    min-width: 0;
    margin-left: 200px;
}

h1.title,
h2.title {
    color: #e3b250;
}

.text-green {
    color: #00703d !important;
}

.register .MuiIconButton-label .MuiSvgIcon-root {
    font-size: 2rem;
}

.login-box .form-control,
.register .form-control,
.register .form-select {
    font-size: 14px;
    padding: 0.8rem 1rem;
}

.register .text-green2 {
    color: #00703d !important;
    font-weight: 400;
}

.register .text-green2:hover {
    color: #00703d !important;
    font-weight: 500;
}

.btn-primary.btn-green,
.bg-green {
    background-color: #00703d;
    border-color: #00703d;
    color: #ffffff;
}

.btn-blue.btn-primary,
.btn-green.btn-success {
    font-weight: 500;
    text-transform: uppercase;
}

.bg-black {
    background-color: #000000;
    color: #ffffff;
}

.cursor-pointer {
    cursor: pointer;
}

.form-inline .inline,
.form-inline .datepicker-inline {
    display: flex;
    align-items: center;
}

.form-inline .datepicker-inline input.datepicker {
    width: 75%;
}

.form-inline .datepicker-inline input.datepicker:focus-visible {
    box-shadow: none;
    outline: none;
}

.search-form .inline input,
.search-form .inline select {
    color: #ffffff;
    background-color: transparent;
    border-color: #ffffff;
}

.company-search {
    width: 40%;
    position: relative;
}

.company-search .form-control {
    padding-right: 30px;
}

.company-search .icons {
    position: absolute;
    top: 10px;
    right: 8px;
}

.card-blue.card {
    background-color: #0065a4;
}

.card-blue.card table {
    color: #ffffff;
}

.card-blue.card table tr>th,
.card-blue.card table tr>td {
    border-bottom-color: #e3b250;
}

.card-blue.card .table> :not(:first-child) {
    border-top: 2px solid #e3b250;
}

.select1 {
    background-image: url('../images/arrow_icon.png');
    background-position: 99.1% 2px;
    background-size: 37px 42px, 37px 42px;
    background-repeat: no-repeat;
}

img.sectorimg {
    width: 58px;
    height: 58px;
}

.margin-left {
    margin-left: 122px;
}

.input-number {
    width: 200px;
    margin-left: 20px;
}

.sectors-box {
    width: 75%;
}

.sectors-list {
    width: 90%;
    margin: 0 auto;
}

.query-checkbox {
    text-align: center;
}

@media (max-width:767px) {
    .app-body>main {
        margin-left: 0;
    }
}

@media only screen and (max-width: 767px) {
    body {
        font-size: 90% !important;
    }

    .p-sm-0 {
        padding: 0 !important;
    }

    .box-wrapper,
    .sectors-list {
        width: 100%;
    }

    .form-inline .inline {
        align-items: baseline;
    }

    .form-inline .inline .input-number {
        margin-left: 0;
    }

    .form-inline .datepicker-inline .inline:last-child {
        margin-left: 5%;
    }

    .form-inline .datepicker-inline input.datepicker {
        width: 100%;
    }

    table.rank-inn-result {
        font-size: 90%;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 481px) {
    .form-inline .inline {
        flex-direction: column;
    }

    .form-inline .inline.heading,
    .form-inline .inline.btn-mobile {
        flex-direction: row;
    }

    .form-inline .inline.heading h4 {
        margin-top: 0;
        font-size: 16px;
    }

    .label-col {
        margin-bottom: .25rem;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 575px) {
    .py-sm-1 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important;
    }

    .margin-left {
        margin-left: 0;
    }

    .sectors-box {
        width: 95%;
    }

    .query-checkbox {
        text-align: left;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation:portrait) {
    .navbar a {
        font-size: 10px;
    }

    .box-content .box-item .box-title h1 {
        font-size: calc(1rem + .9vw) !important;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation:landscape) {
    .box-content .box-item .box-title h1 {
        font-size: calc(1rem + .9vw) !important;
    }
}