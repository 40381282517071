.search-white .MuiOutlinedInput-root,
#sector-multiple-filter {
    border: 1px solid #ffffff;
}

.search-white .MuiChip-root.MuiChip-filled {
    background-color: #ffffff;
}

.search-form .inline input,
.search-form .inline select {
    padding: 0.6rem;
}

.search-form .inline input[type=number] {
    border-color: #ffffff;
}

.search-form.bordertop {
    margin-top: 11px;
    border-top: 1px solid #efad1e;
}

.search-form.borderbottom {
    border-bottom: 1px solid #efad1e;
}

.btn-dark.export:hover,
.btn-dark.export:focus,
.btn-dark.export:active {
    border-color: #006f3d;
    background-color: #006f3d;
}

.company-search-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.company-search-box .text-desktop {
    display: block;
    color: #ffffff;
}

.company-search-box .text-mobile {
    display: none;
    color: #ffffff;
    text-align: justify;
}

.company-search .form-control::placeholder {
    color: #ffffff;
    opacity: 0.9;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
    .company-search-box {
        flex-direction: column;
    }

    .company-search-box .text-desktop {
        display: none;
    }

    .company-search-box .text-mobile {
        display: block;
    }

    .company-search-box .company-search {
        width: 100%;
        margin: 10px 0px;
    }
}