.heading {
    color: #efad1e;
}

.heading h4 {
    font-weight: 700;
    margin-top: -5px;
}

.heading h4>span {
    font-weight: 400;
}

.ticker-list {
    max-height: calc(100vh - 200px);
    overflow-x: hidden;
    overflow-y: auto;
}

.ticker-list .item {
    display: block;
    text-align: center;
    color: #fff;
    cursor: pointer;
}

.ticker-list .item.item-selected {
    color: #efad1e;
}
/*** Scrollbar styles ***/
div::-webkit-scrollbar {
    width: 5px;
}

div::-webkit-scrollbar-track {
    background: #f1f1f1;
}

div::-webkit-scrollbar-thumb {
    background: #000000;
}

div::-webkit-scrollbar-thumb:hover {
    background: #444444;
}