.mt-40 {
    margin-top: 40px !important;
}

.pricing-table {
    font-weight: 700;
    font-size: 4em;
    align-self: center;
    line-height: 1;
}

.currency-symbol {
    font-size: 24px;
    -ms-flex-item-align: start;
    align-self: flex-start;
    line-height: 2.2;
}

.currency-plan {
    font-size: 1.7rem;
    line-height: 1.4;
    align-self: flex-end;
}

ul.list {
    margin-bottom: 0;
}

ul.list>li {
    margin-bottom: 10px;
    text-align: left;
}