.sidebar-width {
    width: 200px;
}

.offcanvas.show.offcanvas-start {
    width: 230px;
    background-color: #005d98;
    transition: background-color .3s ease-in-out;
}

.offcanvas.show.offcanvas-start .sidebar-width {
    width: 229px;
}

.offcanvas.show.offcanvas-start .offcanvas-body {
    padding: 0;
}

.app-body .sidebar,
.offcanvas .sidebar {
    display: flex;
    flex-direction: column;
    padding: 0;
    color: #ffffff;
    position: fixed;
    z-index: 1019;
    height: calc(100vh);
    background-color: #005d98;
    background-image: url('../../images/sidebar_bg.png');
    background-repeat: repeat-x;
    background-position: bottom left;
    background-size: contain;
    transition: background-color .3s ease-in-out;
}

.offcanvas .sidebar {
    background-image: none;
}

.offcanvas.show .offcanvas-header {
    background-color: #005d98;
    color: #ffffff;
}

.offcanvas-header .btn-close {
    color: #f00 !important;
}

.app-body .company-logo {
    text-align: center;
    padding: 1rem 1.5rem 1.5rem 1.5rem;
}

.offcanvas .company-logo {
    text-align: center;
    padding: 1rem 1.5rem 0.5rem 1.5rem;
    border-bottom: 1px solid #e3b250;
    margin-bottom: 0.5rem;
}

.app-body .sidebar .logo,
.offcanvas .sidebar .logo {
    display: block;
    width: 120px;
    height: 120px;
    margin: 0 auto;
    border: 5px solid #fff;
    background-color: #ffffff;
}

.offcanvas .offcanvas-header .logo {
    width: 150px;
}

.offcanvas .sidebar {
    border-right: 0;
}

.app-body .sidebar .nav,
.offcanvas .sidebar .nav {
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 100%;
}

.offcanvas .sidebar .nav {
    width: 229px;
}

.app-body .sidebar .sidebar-nav,
.offcanvas .sidebar .sidebar-nav {
    position: relative;
    -ms-flex: 1;
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    transition: all .3s ease-in-out;
}

.offcanvas .sidebar .sidebar-nav {
    width: 100%;
}

.app-body .sidebar .nav-title {
    padding: 0.9rem 1rem;
    font-size: 11px;
    font-weight: 600;
    color: #ffffff;
    text-transform: uppercase;
}

.offcanvas .sidebar .nav-title {
    display: none;
}

.app-body .sidebar .nav-item,
.offcanvas .sidebar .nav-item {
    position: relative;
    margin: 0;
    transition: background .3s ease-in-out;
}

.app-body .sidebar .nav-item.mobile {
    display: none;
}

.app-body .sidebar .nav-link,
.offcanvas .sidebar .nav-link {
    display: flex;
    align-items: center;
    padding: 0.75rem 1rem;
    color: #ffffff;
    text-decoration: none;
    background: transparent;
    font-size: 14px;
    font-weight: 500;
}

.offcanvas .sidebar .nav-link {
    padding: 0.75rem 2.8rem;
}

.app-body .sidebar .nav-item.dashboard .nav-link,
.offcanvas .sidebar .nav-item.dashboard .nav-link {
    color: #ffffff;
    background-color: #005289;
}

.app-body .sidebar .nav-item.active,
.offcanvas .sidebar .nav-item.active,
.app-body .sidebar .nav-item.active .nav-link:hover,
.offcanvas .sidebar .nav-item.active .nav-link:hover,
.app-body .sidebar .nav-link:focus,
.app-body .sidebar .nav-link:hover,
.offcanvas .sidebar .nav-link:focus,
.offcanvas .sidebar .nav-link:hover {
    color: #ffffff;
    background-color: #00703d;
}

.modal-content.subscription-modal {
    background-color: #005289;
    color: #ffffff;
}

.subscription-modal .icon-box {
    width: 50px;
    margin: 0 auto;
}

.subscription-modal .exclamation-icon {
    width: 50px;
    height: 50px;
    border: 1px solid #e3b250;
    border-radius: 50%;
    padding: 5px;
}

.subscription-modal .heading {
    color: #e3b250;
    text-transform: uppercase;
}

.subscription-modal .btn-warning,
.subscription-modal .btn-warning:hover {
    color: #ffffff;
}