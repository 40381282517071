.welcome-note {
    background-color: #ffffff;
    background-image: url('../../images/dashboard_top_bg.png');
    background-repeat: repeat-x;
    background-position: 0 0;
    background-size: contain;
}

.welcome-note h1 {
    color: #efad1e;
    font-weight: 600;
}

.box-content {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    background-color: #ffffff;
}

.box-content>.box-item {
    flex: 1;
}

.box-content .box-item .box-title {
    background-color: #000000;
    color: #efad1e;
    height: 96px;
}

.box-content .box-item .box-title img {
    width: 50px;
    height: auto;
    margin-left: 20px;
}

.box-content .box-item .box-title:first-child {
    border-right: 1px solid #ffffff;
}

.box-content .box-item .table> :not(:first-child),
.box-content .box-item .table tr>th {
    border-width: 0;
    padding-bottom: 0;
}

.box-content .box-item .table thead>tr>th {
    font-weight: bold;
}

.box-content .box-item .table tr>td {
    border-bottom-color: #e3b250;
}

@media (max-width:767px) {
    .box-content {
        flex-direction: column;
    }
}

.box-content.dashboard a.read-more {
    color: #00703d;
}

.box-content.dashboard a.read-more::after {
    content: ">";
    padding: 0 3px;
}
.mbn {
    position: absolute;
    left: 84px;
    bottom: 1px;
}