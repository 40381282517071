.statistics.nav-tabs {
    border: 0;
}

.tab-icons.nav-link {
    padding: 0.5rem;
}

.tab-icons.nav-link img {
    width: 50px;
    height: 50.9px;
}

.tab-icons.company.nav-link,
.tab-icons.company.nav-link.active {
    background-color: #efad1e;
    border-color: #efad1e;
    border-radius: 0;
    color: #ffffff;
}

.tab-icons.document.nav-link,
.tab-icons.document.nav-link.active {
    background-color: #00703d;
    border-color: #00703d;
    border-radius: 0;
    color: #ffffff;
}

.tab-icons.sentences.nav-link,
.tab-icons.sentences.nav-link.active {
    background-color: #000000;
    border-color: #000000;
    border-radius: 0;
    color: #ffffff;
}

.bg-statistics {
    background-color: #efad1e;
}

.bg-document {
    background-color: #00703d;
}

.bg-sentences {
    background-color: #000000;
}

.company-statistics,
.document-box,
.sentences-box {
    min-height: 655px;
    max-height: 655px;
    overflow-x: hidden;
    overflow-y: auto;
}

.form-inline .label-inline {
    display: flex;
    align-items: center;
    font-size: 1.25rem;
}

.form-inline .label-inline img {
    width: 51px;
    height: 47px;
    margin-right: 5px;
}

.form-inline .btn-blue.btn-primary {
    background-color: #0075bb;
    color: #ffffff;
    padding: 0.6rem .75rem;
}

.form-inline .btn-blue.btn-primary:hover,
.form-inline .btn-blue.btn-primary:focus,
.form-inline .btn-blue.btn-primary:active {
    background-color: #006f3d;
}

.form-inline .btn-green.btn-success {
    background-color: #006f3d;
    color: #ffffff;
    padding: 0.6rem .75rem;
}

.form-inline .btn-green.btn-success:hover,
.form-inline .btn-green.btn-success:focus,
.form-inline .btn-green.btn-success:active {
    background-color: #0075bb;
}

.checkbox .MuiIconButton-label .MuiSvgIcon-root {
    font-size: 2rem;
}