.app-content .fixed-top {
    z-index: 1050;
}

.navbar,
.navbar a {
    color: #ffffff;
    text-decoration: none;
    font-size: 16px;
    font-weight: 400;
}

.navbar a .nav-icon {
    scale: 0.35;
}

.navbar a.active,
.navbar a:hover {
    color: #efad1e;
}

header .search-collapse {
    border-top: 1px solid #efad1e;
}

a.navbar-brand.brand-width {
    width: 200px;
}

a.navbar-brand.brand-width img {
    width: 100%;
}

.navbar .navbar-toggler {
    right: 1rem;
    color: #ffffff;
    padding: 0;
    font-size: 2rem;
}

.navbar .navbar-toggler:focus,
.navbar .navbar-toggler:hover,
.navbar .navbar-toggler:active {
    box-shadow: none;
}

.mobile-search {
    display: none;
}

/* different techniques for iPad screening */
@media only screen and (min-device-width: 481px) and (max-device-width: 767px) {
    .mobile-search {
        display: flex;
        align-items: center;
        margin-right: 25%;
    }
}